import { Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import Default from "./pages/Default";
import "./pages/Home.css"
import Navbar from "./pages/Navbar";
import Formcontact from "./pages/Formcontact";
import Footer from "./pages/Footer";
import Tipos from "./pages/Tipos";



function App() {
  return (
    <div className="App">
<Navbar/>

     <Routes>
      <Route path="/" element={<Home/>}/>     
      <Route path="tipos" element={<Tipos/>}/>
      <Route path="about" element={<About/>}/>
     
    
 
     </Routes>
     <Formcontact/>
     <Footer/>
  
    </div>
  );
}

export default App;
