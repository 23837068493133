import React from 'react'
import "./Tipos.css"


export default function Tipos() {
  return (
  

    <div> 

    <div style={{ marginTop: '100px' }}>
    
    <div className="tipos-content">
      <h2>Tipos de Visados</h2>
      <p>Ofrecemos una variedad de servicios de gestión de visas para tu viaje a Canadá o Estados Unidos. Nuestro equipo de expertos está aquí para ayudarte en cada paso del proceso, asegurándonos de que obtengas la visa adecuada para tus necesidades.</p>
      
      <div className="tipo-visado">
        <h3>Visado de Turismo</h3>
        <p>Si estás planeando un emocionante viaje de turismo a Canadá o Estados Unidos, nuestro equipo te guiará en la solicitud y proceso para obtener el visado de turismo adecuado. Disfruta de explorar los destinos más icónicos, experimenta diferentes culturas y crea recuerdos inolvidables.</p>
        <h4>Requisitos:</h4>
        <ul>
          <li>Reserva de vuelo y alojamiento</li>
          <li>Fondos suficientes para cubrir los gastos del viaje</li>
          <li>Documento de identificación válido</li>
        </ul>
        <h4>Beneficios:</h4>
        <ul>
          <li>Período de estadía para actividades turísticas</li>
          <li>Exploración de lugares turísticos y atracciones</li>
          <li>Participación en eventos y festivales locales</li>
        </ul>
        <h4>Características adicionales:</h4>
        <ul>
          <li>Flexibilidad para planificar tu itinerario</li>
          <li>Posibilidad de extender tu estadía en algunos casos</li>
        </ul>
      </div>

      <div className="tipo-visado">
        <h3>Visado de Negocios</h3>
        <p>Si tienes oportunidades de negocios en Canadá o Estados Unidos, te ayudaremos a obtener el visado de negocios que te permitirá realizar tus actividades comerciales de manera efectiva. Facilita tus interacciones empresariales y aprovecha nuevas oportunidades de crecimiento.</p>
        <h4>Requisitos:</h4>
        <ul>
          <li>Carta de invitación o documentación comercial</li>
          <li>Fondos suficientes para cubrir los gastos de negocios</li>
          <li>Documento de identificación válido y pasaporte</li>
        </ul>
        <h4>Beneficios:</h4>
        <ul>
          <li>Realización de reuniones y acuerdos comerciales</li>
          <li>Exploración de oportunidades de inversión</li>
          <li>Participación en conferencias y ferias empresariales</li>
        </ul>
        <h4>Características adicionales:</h4>
        <ul>
          <li>Posibilidad de viajar con familiares para actividades turísticas</li>
          <li>Extensión de estadía en función de los negocios</li>
        </ul>
      </div>

      <div className="tipo-visado">
        <h3>Visado de Estudios</h3>
        <p>Si estás buscando mejorar tus habilidades y adquirir conocimientos en Canadá o Estados Unidos, el visado de estudios es tu opción. Te asistiremos en la obtención del visado que te permitirá acceder a instituciones educativas de renombre y cumplir tus metas académicas.</p>
        <h4>Requisitos:</h4>
        <ul>
          <li>Carta de aceptación de una institución educativa</li>
          <li>Prueba de fondos suficientes para la matrícula y manutención</li>
          <li>Pasaporte válido y documento de identificación</li>
        </ul>
        <h4>Beneficios:</h4>
        <ul>
          <li>Acceso a programas académicos de alta calidad</li>
          <li>Posibilidad de trabajo a tiempo parcial mientras estudias</li>
          <li>Exploración cultural y aprendizaje en un entorno internacional</li>
        </ul>
        <h4>Características adicionales:</h4>
        <ul>
          <li>Extensión de visado para continuar estudios avanzados</li>
          <li>Participación en eventos y actividades estudiantiles</li>
        </ul>
      </div>


      <div className="tipo-visado">
        <h3>Visado de Trabajo</h3>
        <p>Si buscas avanzar en tu carrera profesional en Canadá o Estados Unidos, nuestro servicio de visado de trabajo te ayudará a obtener el permiso necesario para trabajar legalmente. Aprovecha oportunidades laborales en un entorno internacional y contribuye a tu desarrollo profesional.</p>
        <h4>Requisitos:</h4>
        <ul>
          <li>Oferta de trabajo de un empleador en el país de destino</li>
          <li>Documentos que demuestren tu cualificación y experiencia laboral</li>
          <li>Prueba de fondos suficientes para mantenerte durante tu empleo</li>
        </ul>
        <h4>Beneficios:</h4>
        <ul>
          <li>Experiencia laboral en un mercado internacional</li>
          <li>Desarrollo profesional y aprendizaje de nuevas habilidades</li>
          <li>Contribución a la economía y sociedad del país anfitrión</li>
        </ul>
        <h4>Características adicionales:</h4>
        <ul>
          <li>Posibilidad de solicitar visado para familiares dependientes</li>
          <li>Renovación del visado según la duración del empleo</li>
        </ul>

        <div className="tipo-visado">
        <h3>Visado de Residencia</h3>
        <p>Si estás considerando establecerte de manera permanente en Canadá o Estados Unidos, el visado de residencia es la opción adecuada. Nuestro equipo te ayudará a navegar por el proceso para vivir legalmente en el país y disfrutar de sus beneficios a largo plazo.</p>
        <h4>Requisitos:</h4>
        <ul>
          <li>Documentos que demuestren tu intención de residir permanentemente</li>
          <li>Prueba de fondos suficientes para mantener tu vida en el nuevo país</li>
          <li>Cumplimiento de requisitos de salud y seguridad</li>
        </ul>
        <h4>Beneficios:</h4>
        <ul>
          <li>Derecho a residir y trabajar en el país de manera permanente</li>
          <li>Acceso a servicios sociales y de salud</li>
          <li>Oportunidades para obtener la ciudadanía en el futuro</li>
        </ul>
        <h4>Características adicionales:</h4>
        <ul>
          <li>Posibilidad de solicitar visado para familiares dependientes</li>
          <li>Compromiso con las leyes y regulaciones del país anfitrión</li>
        </ul>
      </div>
      </div>
      
      

    </div>

    

    

    </div>
    </div>
  )
}
