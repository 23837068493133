import React from 'react'
import { useRef } from 'react';
import "./footer.css";
import logo from "./logo.png";
import { Link } from 'react-router-dom';

export default function Footer() {
  
  const navRef = useRef();

  return (
    <div className='footer-container'>

        <div className="first-column">
           
            <p className='title-footer'>Full visa USA</p>
            <img src={logo} className='footer-logo' alt="" />
        </div>

        <div className="second-column">
        <nav className='nav-footer' ref={navRef}>
        <Link to="/">HOME</Link>
        <Link to="/About">SOBRE NOSOTROS</Link>
        <Link to="/Tipos">TIPOS</Link>  
        <Link to="/contacto">CONTACTO</Link>
        </nav>
        </div>

        <div className="three-column">
        <Link to="/Tipos">TIPOS DE VISADOS</Link>  
        <p>Estudio</p>
        <p>Trabajo</p>
        <p>Turismo</p>
        <p>Negocios</p>
        <p>Residencia</p>
        </div>

    </div>
  )
}
