import React, { useRef } from 'react';
import { FaBars } from 'react-icons/fa';
import logob from "./logob.png";
import { Link } from 'react-router-dom';
import "./Navbar.css"


export default function Navbar() {

    const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <div>

<header>
      <img src={logob} className="logo" alt="" />
      <nav className='nav-navbar' ref={navRef}>
        <Link to="/">HOME</Link>
        <Link to="/About">SOBRE NOSOTROS</Link>
        <Link to="/Tipos">TIPOS</Link>  
        <Link to="/contacto">CONTACTO</Link>
        <button
          className="nav-btn nav-close-btn"
          onClick={showNavbar}
        ></button>
      </nav>
      <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
      </button>
    </header>

    </div>
  )
}
