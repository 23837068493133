import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import "./formcontact.css";

export default function Formcontact() {

    const [isSent, setIsSent] = useState(false);
 
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
      emailjs.sendForm('service_66ttpk5', 'template_bmgb8e7', form.current, 'HXu-_V8WlY7lWHzGy')
        .then((result) => {
          console.log(result.text);
          setIsSent(true); // Establecer el estado en "Enviado"
          form.current.reset(); // Restablecer los datos del formulario
        })
        .catch((error) => {
          console.log(error.text);
        });
    };

  return (
    <div className="container" style={{ marginTop: '100px' }}>
    <h3>PONTE EN CONTACTO</h3>
    <form ref={form} onSubmit={sendEmail}>
    <label>Nombre</label>
      <input type="text" name="user_name" />
      <label>Email</label>
      <input type="email" name="user_email" />
      <label>Mensaje</label>
      <textarea name="message" />
      <input className='send-button' type="submit" value="Enviar" />
  
    </form>
    {isSent && <p>Enviado</p>} {/* Mostrar la notificación si isSent es true */}
  </div>
  )
}
