import React from 'react'
import background from "../background.jpg"
import TRABAJO from "../TRABAJO.png"
import TURISMO from "../TURISMO.png"
import ESTUDIO from "../ESTUDIO.png"
import NEGOCIOS from "../NEGOCIOS.png"
import RESIDENCIA from "../RESIDENCIA.png"
import WHATSAPP from "../whatsapp.png"
import { useState, useEffect } from 'react'
import { FaPassport, FaUserTie, FaClock } from "react-icons/fa";
import CountUp from "react-countup"
import "./Home.css"
import { Link } from 'react-router-dom'

export default function Home() {


  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulación de una operación asíncrona que tarda en cargar
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);


  useEffect(() => {
    startTypingAnimation();
  }, []);

  const startTypingAnimation = () => {
    const words = document.querySelectorAll('.queres, .obtener, .tuvisa');
    words.forEach((word, index) => {
      setTimeout(() => {
        word.classList.add('typing');
      }, index * 500); // Agrega un retraso progresivo para cada palabra
    });
  };

  return (
    <div className="App">

      
    
    <div className='background-image-container'>
      <img className='background-image' src={background} alt="" />
      <div className='text-overlay'>
        <h3 className='queres'>¿QUERES</h3>
        <h3 className='obtener'>OBTENER</h3>
        <h3 className='tuvisa'>TU VISA?</h3>

      </div>
    </div>

        <div>
          <a href="https://wa.link/wkm2ya">
            <img className='whatsapp' src={WHATSAPP} width="150"/>
          </a>
        </div>


    <div className="counter-section">

      <h3>SOLICITA TU VISA FACILMENTE</h3>
      <p className='red'>TU ACCESO RÁPIDO AL VISADO AMERICANO <br /> CON NUESTROS EXPERTOS GESTORES</p>


      <div className="datos-count">

        <div className="visas-otorgadas blue">


          <i> <FaPassport /> </i>
          <p> + <CountUp start={0} end={100} duration={3.5} delay={0} />{' '}
            VISAS <br />
            OTORGADAS
          </p>
        </div>

        <div className="visas-otorgadas red">

          <i> <FaUserTie />  </i>
          <p> + <CountUp start={0} end={7} duration={3.5} delay={0} />{' '}
            AÑOS EN EL  <br />
            RUBRO
          </p>


        </div>

        <div className="visas-otorgadas blue">


          <i> <FaClock /> </i>
          <p>ADELANTAMOS <br /> TU TURNO</p>
        </div>

      </div>

    </div>

    {/* <div className="banner-section">
      <img src={miamiBanner} className='miamiBanner' alt="" />
    </div> */}

    <div className="tipos-section">
      <h3>¿QUE TIPOS DE VISADOS TRAMITAMOS?</h3>
      <p className='blue'>EXPLORA LA VARIEDAD DE VISADOS QUE <br /> OFRECEMOS</p>





      <div className="container-card">
        <div className="card-tipos">
          <div className="img-card-tipos">
            <img src={TURISMO} className='img-card' alt="" />
          </div>

          <button>
            <span class="circle1"></span>
            <span class="circle2"></span>
            <span class="circle3"></span>
            <span class="circle4"></span>
            <span class="circle5"></span>
            <span class="text"> <Link to="/Tipos">VER MAS</Link></span>
          </button>
        </div>

        <div className="card-tipos">
          <div className="img-card-tipos">
            <img src={ESTUDIO} className='img-card' alt="" />
          </div>

          <button>
            <span class="circle1"></span>
            <span class="circle2"></span>
            <span class="circle3"></span>
            <span class="circle4"></span>
            <span class="circle5"></span>
            <span class="text"> <Link to="/Tipos">VER MAS</Link></span>
          </button>
        </div>

        <div className="card-tipos">
          <div className="img-card-tipos">
            <img src={TRABAJO} className='img-card' alt="" />
          </div>

          <button>
            <span class="circle1"></span>
            <span class="circle2"></span>
            <span class="circle3"></span>
            <span class="circle4"></span>
            <span class="circle5"></span>
            <span class="text"> <Link to="/Tipos">VER MAS</Link></span>
          </button>
        </div>

        <div className="card-tipos">
          <div className="img-card-tipos">
            <img src={NEGOCIOS} className='img-card' alt="" />
          </div>

          <button>
            <span class="circle1"></span>
            <span class="circle2"></span>
            <span class="circle3"></span>
            <span class="circle4"></span>
            <span class="circle5"></span>
            <span class="text"> <Link to="/Tipos">VER MAS</Link></span>
            
          </button>
        </div>

       

        <div className="card-tipos">
          <div className="img-card-tipos">
            <img src={RESIDENCIA} className='img-card' alt="" />
          </div>

          <button>
            <span class="circle1"></span>
            <span class="circle2"></span>
            <span class="circle3"></span>
            <span class="circle4"></span>
            <span class="circle5"></span>
            <span class="text"> <Link to="/Tipos">VER MAS</Link></span>
          </button>
        </div>

      </div>

    </div>



    <div className='testimonios' id='testimonios'>
       <h3>TESTIMONIOS</h3>
       <p>Lo que nuestros clientes dicen de nosotros:</p>
<div className="clientes">
  <div className="cliente">
    <h5>Gimena</h5>
    <h6>Mar del Plata</h6>
    <h6>"Me adelantaron mi <br /> turno a 1 Mes, increible <br /> la rapidez"</h6>
  </div>

  <div className="cliente">
    <h5>Nicolas</h5>
    <h6>BUENOS AIRES</h6>
    <h6>"Me ayudaron a tramitar <br /> mi visa de negocios <br />super recomendados!"</h6>
  </div>

  <div className="cliente">
    <h5>Victoria</h5>
    <h6>BUENOS AIRES</h6>
    <h6>"Tramitaron la visa <br /> de mis hijos y marido, para <br /> nuestro viaje a NY"</h6>
  </div>
</div>

    </div>


 






  </div>
  )
}
