import React from 'react'
import Navbar from './Navbar'
import './About.css';
export default function About() {
  return (
    <div className="about-us" style={{ marginTop: '100px' }}>
    <h2>Sobre nosotros</h2>
    <p>En <strong> FULLVISA </strong> , nos especializamos en proporcionar una amplia gama de servicios de gestión de visados para Estados Unidos y Canadá. Con 7 años de experiencia en la industria, nos enorgullece haber ayudado a numerosos individuos y empresas a obtener sus visados de manera exitosa.</p>
    
    <h3>Visados Ofrecidos</h3>
    <p>Ofrecemos servicios de gestión de visados para una variedad de propósitos, incluyendo:</p>
    <ul>
      <li>Visa de Turista: Para aquellos que desean visitar Estados Unidos o Canadá por motivos turísticos.</li>
      <li>Visa de Negocios: Para personas que planean realizar actividades comerciales o asistir a conferencias en Estados Unidos o Canadá.</li>
      <li>Visa de Estudiante: Para aquellos que desean estudiar en instituciones educativas en Estados Unidos o Canadá.</li>
      <li>Visa de Trabajo: Para individuos que han sido contratados por una empresa en Estados Unidos o Canadá.</li>
      <li>Visa de Residencia Permanente: Para aquellos que desean establecerse de forma permanente en Estados Unidos o Canadá.</li>
    </ul>
    
    <h3>Aranceles</h3>
    <p>Los aranceles varían según el tipo de visado y los servicios adicionales que se requieran. Te recomendamos ponerte en contacto con nosotros para obtener información actualizada sobre los aranceles correspondientes a cada visado específico.</p>
    
    <h3>Contacto</h3>
    <p>Puedes comunicarte con nosotros a través de los siguientes medios:</p>
    <ul>
      <li>Teléfono: 11 6923-6934</li>  <li>Correo electrónico: fullvisa.usa@gmail.com</li>
    </ul>
    {/* Agrega una sección para la dirección de la oficina si es relevante */}
    
    <h3>Nuestra Experiencia</h3>
    <p>Con 7 años de experiencia en la industria de gestión de visados, estamos comprometidos en ofrecer un servicio de calidad y resultados positivos. Hasta la fecha, hemos tramitado con éxito un gran número de visados para nuestros clientes satisfechos.</p>
    
    <h3>Testimonios</h3>
    <p>Nuestros clientes han compartido sus experiencias con nosotros. Puedes leer algunos de sus testimonios en nuestra página web.</p>
  </div>

    
  )
}
